import * as Sentry from '@sentry/browser';
import { get } from 'lodash';
import { warningToast } from 'toast-service!sofe';
import { isOverride, getAllManifests } from 'sofe';
import {
  getAssociatedService,
  getSquad,
  getVersioningInformation,
  getFeatureToggleInfo,
} from './error.helpers.js';

function getWhiteListUrls() {
  return [
    'canopy.ninja',
    'canopy.dev',
    'canopytax.com',
    'localhost',
    'ielocal',
    // white label domains
    'clientportal.ninja',
    'clientportal.com',
  ];
}

let suppressErrors;
let appIsUnloading = false;

export function beforeSend(manifest, event, hint) {
  if (appIsUnloading) {
    return null; // Skip errors that occur while the app is unloading (or reloading)
  }

  try {
    const { exception } = event;
    const frames = get(exception, 'values[0].stacktrace.frames');

    const errorUrl = frames ? frames[frames.length - 1].filename : '';
    let error = hint.originalException;

    if (typeof error === 'string') error = new Error(error);

    if (
      errorUrl &&
      !getWhiteListUrls().find(matcher => errorUrl.includes(matcher))
    )
      return null; // the error occurred from an unknown domain

    error = handleToaster(error);

    if (hasSofeOverrides()) return null;

    event = addErrorData(manifest, event, error, window.loggedInUser);
  } catch (resolutionError) {
    console.error('Error resolving error: ', resolutionError);
  }

  return Promise.resolve(event);
}

function addErrorData(manifest, event, error, loggedInUser = {}) {
  if (error && error.showToast) {
    const toastBreadcrumb = {
      category: 'console',
      level: 'error',
      message: `WARNING-TOAST: ${error.toastMessage}`,
      timestamp: Date.now() / 1000,
    };

    event = {
      ...event,
      breadcrumbs: [...(event.breadcrumbs || []), toastBreadcrumb],
    };
  }

  if (error) {
    const service = getAssociatedService(event.exception, manifest);
    const versions = getVersioningInformation(manifest, service);
    const toggles = getFeatureToggleInfo();

    event = {
      ...event,
      tags: {
        ...(event.tags || {}),
        ...(error.tags || {}),
        toastDisplayed: error.showToast,
        toastMessage: error.toastMessage,
        service: service,
        squad: getSquad(service),
        userName: loggedInUser.name,
        userEmail: loggedInUser.email,
        userRole: loggedInUser.role,
      },
      extra: {
        ...(event.extra || {}),
        ...(error.extra || {}),
        toastMessage: error.toastMessage,
        toggles: JSON.stringify(toggles),
        versions: JSON.stringify(versions),
      },
    };
  }

  return event;
}

export function handleToaster(error) {
  if (error && error.showToast !== false && !suppressErrors) {
    error.showToast = true;
    error.toastMessage =
      error.toastMessage ||
      get(error, 'data.errors.userMessage') ||
      `Something went wrong... We're working fast to fix it!`;
    warningToast(error.toastMessage);
  }
  return error;
}

function updateOnlineStatus(event) {
  Sentry.addBreadcrumb({
    category: 'connection',
    message:
      'Online status changed: ' + (navigator.onLine ? 'online' : 'offline'),
    level: 'info',
  });
}

window.addEventListener('online', updateOnlineStatus);
window.addEventListener('offline', updateOnlineStatus);

window.addEventListener('beforeunload', function() {
  appIsUnloading = true;
});

export function hasSofeOverrides() {
  const localStorageLogSentryWithSofe =
    localStorage.getItem('logSentryWithSofe') === 'true';

  if (
    isOverride() &&
    !window.logSentryWithSofe &&
    !localStorageLogSentryWithSofe
  ) {
    /* eslint-disable */
    console.log(
      `Nothing will be logged to sentry, since there is a sofe override. Run "window.logSentryWithSofe = true" or "localStorage.setItem('logSentryWithSofe', 'true')" if that's not what you want.`
    );
    /* eslint-enable */
    return true;
  }

  if (localStorageLogSentryWithSofe) {
    /* eslint-disable */
    console.log(
      `Message will be logged to sentry even though there is a sofe override. Run "localStorage.removeItem('logSentryWithSofe')" if that's not what you want.`
    );
    /* eslint-enable */
  }

  return false;
}
